import React, { useState, useEffect } from "react";
import Products from "./Products";
import banner from "../../../assets/images/metallica banner.png"
import axios from "axios";

const CleanRoomAccessories = () => {
    document.title = "Metallica | Clean Room Accessories";
    const [products, setProducts] = useState([]);
  
    useEffect(() => {
      // Fetch products when the component mounts
      const fetchProducts = async () => {
        try {
          const response = await axios.get(
            "https://server.metallicacreation.com/products/?category=5"
          );
          setProducts(response.data);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
  
      fetchProducts();
    }, []);
  return (
    <>
    <div className="banner">
      <img src={banner} className="w-100" alt="" />
    </div>
    <div className="product py-5 bg-white">
      <div className="container">
        <h1>
          Clean Room <span>Accessories</span>
        </h1>
        <p>
          We are engaged in offering our clients tables that made from SS 304
          / SS 316 with matt finish. The tables made as per customer
          application and quality norms of the market, thus, durability and
          longer working life is always ensured. The tables are extensively
          used in laboratories, biopharmaceuticals, hospitals, canteen & food
          industries. The tables will be cost-effective and user-friendly. The
          supportive service team will help the customers to solve their
          problems very frequently. The good quality of the material used in
          making the products will increase the life span of the products. The
          proper quality and standards of the products will be maintained by
          our company. The excellent and satisfying quality of the products
          will help in achieving the goals of the company. The cleanroom
          tables are self-adjustable and manageable.
        </p>
        <Products products={products} />
      </div>
    </div>
  </>
  )
}

export default CleanRoomAccessories