import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandYoutube,
  IconMailFilled,
  IconPhoneFilled,
} from "@tabler/icons-react";
import React from "react";
import logo from "../../assets/images/logo.png"
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <>
    <div className="footer py-3">
      <div className="container">
        <div className="row py-5">
          <div className="col-md-3">
            <h3>Metallica</h3>
            <hr />
            <img src={logo} width={150} alt="" />
            <hr />
            <h4 className="mt-4">
              Connect With <span>Us</span>
            </h4>
            <div className="d-flex gap-3 pt-3">
              <a href="">
                <IconBrandFacebook width={30} />
              </a>
              <a href="">
                <IconBrandInstagram width={30} />
              </a>
              <a href="">
                <IconBrandLinkedin width={30} />
              </a>
              <a href="">
                <IconBrandYoutube width={30} />
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <h3>
              Useful <span>Links</span>
            </h3>
            <hr />
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/corporate-overview">Corporate Overview</Link>
            </li>
            <li>
              <Link to="/news-and-event">News & Event</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
            <li>
              <Link to="/download">Download</Link>
            </li>
            <li>
              <Link to="/our-client">Our Client</Link>
            </li>
          </div>
          <div className="col-md-3">
            <h3>
              Contact <span>Us</span>
            </h3>
            <hr />
            <address>
            Plot no 97, <br /> Sector 57, Phage IV, <br /> HSIIDC Kundli, Sonipat, <br /> Haryana - 131028
            </address>
            <hr />
            <address>
            Plot no 100, <br /> Ecotech - II,<br /> Greater Noida, <br /> Uttar Pradesh - 203202
            </address>
            <li>
              {" "}
              <a
                href="mailto:info@metallicacreation.com"
                rel="noreferrer"
                target="_blank"
                className="d-flex gap-2"
              >
                <IconMailFilled width={20} />{" "}
                <span>info@metallicacreation.com</span>
              </a>
            </li>
            <li>
              {" "}
              <a
                href="tel:+91-7082000473"
                rel="noreferrer"
                target="_blank"
                className="d-flex gap-2"
              >
                <IconPhoneFilled width={20} /> <span>+91-7082000473, 9999080543</span>
              </a>
            </li>
          </div>
          <div className="col-md-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13974.64243510985!2d77.1248135!3d28.8787113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dab63b59687db%3A0x6c7b6865d6135fb6!2sMetallica%20creation!5e0!3m2!1sen!2sus!4v1701084609810!5m2!1sen!2sus"
              width="100%"
              height="100%"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
        <div className="copyright mb-0">
          <p className="py-2 text-light text-center">
            2023 &copy; Design & Developed By{" "}
            <a href="https://juneco.in/" target="_blank">
              Juneco Infotech Pvt Ltd
            </a>
          </p>
        </div></>
  );
};

export default Footer;
