import React, { useState } from "react";
import { IconEye, IconNorthStar } from "@tabler/icons-react";
import f4 from "../../assets/images/f4.png";
import p2 from "../../assets/images/p2.png";
import banner1 from "../../assets/images/1.png";
import banner2 from "../../assets/images/2.png";
import banner3 from "../../assets/images/ttt2.png";
import airFlow from "../../assets/images/air-flow-unit.png";
import airShower from "../../assets/images/air-shower.png";
import hood from "../../assets/images/hood.png";
import kisok from "../../assets/images/kisok.png";
import mrs from "../../assets/images/m-r-s.png";
import crf from "../../assets/images/clean-room-funrniture.png";
import { Link } from "react-router-dom";

const Home = () => {
  document.title = "Metallica";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();

    const whatsappLink = `https://wa.me/9999080543?text=Name: ${formData.name}%0AEmail: ${formData.email}%0APhone: ${formData.phone}%0AMessage: ${formData.message}`;

    // Redirect to WhatsApp link
    window.open(whatsappLink, '_blank');

  };

  const categories = [
    {
      id: 1,
      name: "Clean Room",
      span: "Furniture",
      route: "clean-room-furniture",
      img: crf
    },
    {
      id: 2,
      name: "Laboratory",
      span: "Furniture",
      route: "laboratory-funiture",
      img: f4
    },
    {
      id: 3,
      name: "Pass",
      span: "Box",
      route: "pass-boxes",
      img: p2
    },
    {
      id: 4,
      name: "Air Flow",
      span: "Unit",
      route: "air-flow-unit",
      img: airFlow
    },
    {
      id: 5,
      name: "Air",
      span: "Shower",
      route: "air-shower",
      img: airShower
    },
    {
      id: 6,
      name: "Hood",
      span: "",
      route: "hood",
      img: hood
    },
    {
      id: 8,
      name: "KIOSK",
      span: "",
      route: "kiosk",
      img: kisok
    },
    {
      id: 9,
      name: "Metail Retail Showroom",
      span: "",
      route: "metail-reatil-showroom",
      img: mrs
    },
  ];
  return (
    <>
      {/* hero section  */}
      <div className="hero">
        <div
          id="carouselExampleFade"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={banner1} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item active">
              <img src={banner2} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item active">
              <img src={banner3} class="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* hero section end  */}
      {/* product  */}
      <div className="product py-5">
        <div className="container">
          <h1 className="text-center py-4">Our <span>Products</span></h1>
          <div className="row gap-3 justify-content-center">
            {categories.map((category) => (
              <div className="col-lg-3 bg-white border rounded py-2 text-center shadow-sm">
                <img src={category.img} alt="" className="p-2 w-100" />
                <h3>
                  {category.name} <span>{category.span}</span>
                </h3>
                <div className="my-3">
                  <Link title="Home" to={category.route}>
                    <button className="primary-button border-0">View More</button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* product  */}
      {/* corporate Overview  */}
      <div className="corporate-overview">
        <div className="container my-5">
          <h1 className="text-center">
            <span>Corporate</span> Overview
          </h1>
          <p className="text-center">
            Welcome to Mettalica Creation, where precision meets innovation in
            the world of steel manufacturing. Based in Sonipat, Haryana, we are
            a leading company dedicated to crafting high-quality steel goods
            that redefine durability and style.{" "}
            <a href="#">
            </a>
            <Link to="corporate-overview">
              <span>Read More.</span>
            </Link>
          </p>

          <div className="row py-3 gap-3 justify-content-center">
            <div class="card">
              <img
                src="https://images.unsplash.com/photo-1505409859467-3a796fd5798e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                class="card-img-top py-2"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">
                  About <span>Us</span>
                </h5>
                <p class="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
                <Link to="corporate-overview"> 
                <button className="primary-button border-0">Go somewhere</button> 
                </Link>
                 
              </div>
            </div>
            <div class="card">
              <img
                src="https://images.unsplash.com/photo-1517502884422-41eaead166d4?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                class="card-img-top py-2"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">
                  Our <span>Mission</span>
                </h5>
                <p class="card-text">
                  At Mettalica Creation, our mission is to lead the steel
                  manufacturing industry with innovative, high-quality products
                  that
                </p>
                <Link to="corporate-overview"> 
                <button className="primary-button border-0">Go somewhere</button> 
                </Link>
              </div>
            </div>
            <div class="card">
              <img
                src="https://images.unsplash.com/photo-1531973576160-7125cd663d86?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                class="card-img-top py-2"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">
                  <span>Why</span> Us ?
                </h5>
                <p class="card-text">
                  Choose Mettalica Creation for unparalleled steel
                  craftsmanship, where innovation meets durability. Elevate your
                  spaces with our high-quality products, setting new standards
                  in style and functionality.
                </p>
                <Link to="corporate-overview"> 
                <button className="primary-button border-0">Go somewhere</button> 
                </Link>
              </div>
            </div>
            <div class="card">
              <img
                src="https://images.unsplash.com/photo-1497366672149-e5e4b4d34eb3?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                class="card-img-top py-2"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">
                  Our <span>Vision</span>
                </h5>
                <p class="card-text">
                  Our vision at Mettalica Creation is to be the forefront choice
                  for individuals and businesses seeking top-tier steel
                  products....
                </p>
                <Link to="corporate-overview"> 
                <button className="primary-button border-0">Go somewhere</button> 
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="row py-5">
            <div className="col-md-6 align-self-center">
              <img
                src="https://images.unsplash.com/photo-1614533836002-5728f1963983?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="director-img p-2 rounded shadow-sm bg-white"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <h1>
                Director <span>Message</span>
              </h1>
              <p>
                Welcome to Mettalica Creation, a company that stands at the
                forefront of innovation and excellence in the steel
                manufacturing industry. It is my pleasure to extend a warm
                greeting to all those who have contributed to our journey and
                have placed their trust in our brand. At Mettalica Creation, our
                commitment goes beyond crafting steel goods; it is about
                creating enduring experiences. Our foundation is built on the
                pillars of precision, quality, and customer satisfaction. With a
                focus on innovation, we continuously strive to elevate our
                products, ensuring they meet the evolving needs of our
                discerning clientele. As the Director, I take immense pride in
                our team of dedicated professionals who work tirelessly to bring
                you products that seamlessly blend functionality with
                aesthetics. Our state-of-the-art manufacturing facility in
                Sonipat, Haryana, reflects our dedication to technological
                advancement and sustainability in every aspect of our
                operations. Mettalica Creation is not just a manufacturer; we
                are architects of steel elegance. Whether it's the sleek lines
                of our chairs, the robust design of our stools, or any other
                product in our portfolio, each item tells a story of
                craftsmanship, durability, and timeless style. As we continue to
                grow, our commitment to ethical business practices,
                environmental responsibility, and customer satisfaction remains
                unwavering. We invite you to explore our range of steel
                creations, crafted with passion and precision. Thank you for
                choosing Mettalica Creation — where strength meets
                sophistication.
              </p>
              <a href="">
                <span>Read More</span>
              </a>
            </div>
          </div> */}
        </div>
      </div>
      {/* corporate Overview End */}
      {/* clean room furniture  */}
      {/* <div className="clean-room-furniture">
        <div className="container my-5">
          <h1 className="text-center pt-3">
            <span>Clean Room</span> Furniture
          </h1>
          <p className="text-center">
            We are one th finest manufacturer and supplire of a wise range of a
            clean room furniture.
          </p>
          <hr />
          <div className="row py-4 gap-2 justify-content-center">
            <div className="col-md-2">
              <img src={stool} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={table} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={redAmirah} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img
                src={blueAmirah}
                className="w-100 shadow-sm rounded"
                alt=""
              />
            </div>
            <div className="col-md-2">
              <img src={trolley} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={chair} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={locker} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img
                src={steelAlmirah}
                className="w-100 shadow-sm rounded"
                alt=""
              />
            </div>
            <div className="col-md-2">
              <img src={Rack} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={scoops} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-12 d-flex pt-5 justify-content-center">
              <a href="#" className="primary-button shadow-sm">
                Show All
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* clean room furniture  */}
      {/* LABORATORY FURNITURE */}
      {/* <div className="laboratary-funiture bg-white py-5">
        <div className="container">
          <h1 className="text-center">
            <span>Laboratory</span> Furniture
          </h1>
          <p className="text-center">
            We are one th finest manufacturer and supplire of a wise range of a
            clean room furniture.
          </p>
          <hr />
          <div className="row py-4 gap-2 justify-content-center">
            <div className="col-md-2">
              <img src={f1} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f2} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f3} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f4} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f5} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f6} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f7} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f8} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f9} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-2">
              <img src={f1} className="w-100 shadow-sm rounded" alt="" />
            </div>
            <div className="col-md-12 d-flex pt-5 justify-content-center">
              <a href="#" className="primary-button shadow-sm">
                Show All
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* LABORATORY FURNITURE */}
      {/* pass box */}
      {/* <div className="pass-box py-5">
        <div className="container">
          <h1 className="text-center">
            <span>Pass</span> Box
          </h1>
          <p className="text-center">
            We are one th finest manufacturer and supplire of a wise range of a
            clean room furniture.
          </p>
          <hr />
          <div className="row py-4 gap-2 justify-content-center">
            <div className="col-md-5">
              <img src={p1} className="w-100 rounded shadow-sm" alt="" />
            </div>
            <div className="col-md-5">
              <img src={p2} className="w-100 rounded shadow-sm" alt="" />
            </div>
          </div>
        </div>
        <div className="col-md-12 d-flex pt-5 justify-content-center">
          <a href="#" className="primary-button shadow-sm">
            Show All
          </a>
        </div>
      </div> */}
      {/* pass box */}
      {/* contact  */}
      <div className="contact py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="text-center">
                Contact <span>Us</span>
              </h1>
              <form onSubmit={submitForm}>
                <div className="mb-2">
                  <label htmlFor="name">
                    Name{" "}
                    <sup className="text-danger">
                      <IconNorthStar width={12} />
                    </sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="py-2 form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="py-2 form-control"
                    placeholder="name@example.com"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="phone">
                    Phone{" "}
                    <sup className="text-danger">
                      <IconNorthStar width={12} />
                    </sup>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    className="py-2 form-control"
                    placeholder="+91-9000000000"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="message">
                    Message{" "}
                    <sup className="text-danger">
                      <IconNorthStar width={12} />
                    </sup>
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="form-control"
                    cols="30"
                    placeholder="Your message here."
                    rows="10"
                  ></textarea>
                </div>
                <div className="mb-2">
                  <input
                    type="submit"
                    className="form-control primary-button"
                  />
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13974.64243510985!2d77.1248135!3d28.8787113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dab63b59687db%3A0x6c7b6865d6135fb6!2sMetallica%20creation!5e0!3m2!1sen!2sin!4v1701152168636!5m2!1sen!2sin"
                width="100%"
                height="550"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* contact  */}
    </>
  );
};

export default Home;
