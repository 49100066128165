import React from 'react'
import Products from "./Products";
import banner from "../../../assets/images/metallica banner.png";
import kiosk from "../../../assets/images/kisok.png"

const Kiosk = () => {
    document.title = "Metallica | Hood";
    const products = [
      {
        id: 1,
        name: "KIOSK",
        image: kiosk
      },

    ] 
  return (
    <>
     <div className="banner">
      <img src={banner} className="w-100" alt="" />
    </div>
    <div className="product py-5 bg-white">
      <div className="container">
        <h1 className='text-center'>
          <span>Hood</span>
        </h1>
        <p>
         
        </p>
        <Products products={products} />
      </div>
    </div>
    </>
  )
}

export default Kiosk