import React from 'react'

const Download = () => {
    document.title = "Metallica | Downloads"
  return (
    <>
     <div className="banner">
        <img src="https://dummyimage.com/1920x500" className="w-100" alt="" />
      </div>
      <div className="gallery py-5">
        <div className="container">
          <h1>
            Catlog <span>Download</span>
          </h1>
          <img
            src="http://royalrubysalonandacademy.com/image.png"
            className="w-100"
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default Download