import React from "react";
import banner from "../../assets/images/metallica banner.png";
import vision from "../../assets/images/vision.jpg";

const CorporateOverview = () => {
  document.title = "Metallica | Corporate Overview";
  return (
    <>
      <div className="banner">
        <img src={banner} className="w-100" alt="" />
      </div>
      <div className="about-company bg-white py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 align-self-center">
              <img
                src="https://images.unsplash.com/photo-1606857521015-7f9fcf423740?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <h1 className="text-center">
                About <span>Metallica</span>
              </h1>
              <p className="">
                Welcome to Mettalica Creation, where precision meets innovation
                in the world of steel manufacturing. Based in Sonipat, Haryana,
                we are a leading company dedicated to crafting high-quality
                steel goods that redefine durability and style. At Mettalica
                Creation, we specialize in the production of an array of steel
                products, ranging from chairs to stools and beyond. Our
                commitment to excellence is evident in every piece we create.
                With a state-of-the-art manufacturing facility and a team of
                skilled craftsmen, we ensure that each product not only meets
                but exceeds industry standards. The Mettalica Creation
                experience is characterized by a seamless blend of cutting-edge
                technology and timeless craftsmanship, resulting in steel goods
                that are not just functional but also aesthetically pleasing. As
                a customer-centric company, we prioritize your needs and
                preferences. Whether you are furnishing a corporate space, a
                modern home, or any other environment, our diverse range of
                steel products caters to various design sensibilities and
                applications. We take pride in our ability to deliver solutions
                that seamlessly integrate into your lifestyle and space.
                Mettalica Creation is more than just a manufacturing company; we
                are a symbol of reliability, innovation, and enduring quality.
                Explore our collection and discover the strength and elegance
                that define Mettalica Creation's steel creations. Welcome to a
                world where steel meets sophistication.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mission py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center">
              <h1>
                Our <span>Mission</span>
              </h1>
              <p>
                At Mettalica Creation, our mission is to lead the steel
                manufacturing industry with innovative, high-quality products
                that redefine durability and style. We are committed to
                precision craftsmanship, customer satisfaction, continual
                innovation, sustainability, ethical business practices, and
                community engagement. Our goal is to be a symbol of reliability,
                innovation, and enduring quality in the steel manufacturing
                landscape
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="https://images.unsplash.com/photo-1517502884422-41eaead166d4?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="w-100 rounded"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="vision py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={vision} className="w-100" alt="" />
            </div>
            <div className="col-md-6 align-self-center">
              <h1>
                Our <span>Vision</span>
              </h1>
              <p>
                Our vision at Mettalica Creation is to be the forefront choice
                for individuals and businesses seeking top-tier steel products
                that seamlessly blend durability, style, and innovation. We
                aspire to set new standards in the industry, creating enduring
                solutions that enhance spaces and lifestyles globally
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorporateOverview;
