import { IconNorthStar } from "@tabler/icons-react";
import React, { useState } from 'react';
import banner from "../../assets/images/metallica banner.png";

const Contact = () => {
  document.title = "Metallica | Contact Us";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();

    const whatsappLink = `https://wa.me/9999080543?text=Name: ${formData.name}%0AEmail: ${formData.email}%0APhone: ${formData.phone}%0AMessage: ${formData.message}`;

    // Redirect to WhatsApp link
    window.open(whatsappLink, '_blank');

  };
  return (
    <>
      <div className="banner">
        <img src={banner} className="w-100" alt="" />
      </div>
      <div className="contact py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="text-center">
                Contact <span>Us</span>
              </h1>
              <form onSubmit={submitForm}>
                <div className="mb-2">
                  <label htmlFor="name">
                    Name{" "}
                    <sup className="text-danger">
                      <IconNorthStar width={12} />
                    </sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="py-2 form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="py-2 form-control"
                    placeholder="name@example.com"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="phone">
                    Phone{" "}
                    <sup className="text-danger">
                      <IconNorthStar width={12} />
                    </sup>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    className="py-2 form-control"
                    placeholder="+91-9000000000"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="message">
                    Message{" "}
                    <sup className="text-danger">
                      <IconNorthStar width={12} />
                    </sup>
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="form-control"
                    cols="30"
                    placeholder="Your message here."
                    rows="10"
                  ></textarea>
                </div>
                <div className="mb-2">
                  <input
                    type="submit"
                    className="form-control primary-button"
                  />
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14254.44010520923!2d83.3873828!3d26.7249027!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399144beb452d489%3A0xaf58a1d0c65f9670!2sJuneco%20Infotech%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1700476618774!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
