import React from 'react'
import banner from "../../assets/images/metallica banner.png"

const OurClient = () => {
    document.title = "Metallica | Our Client"
  return (
    <>
     <div className="banner">
        <img src={banner} className="w-100" alt="" />
      </div>
    
     <div className="gallery py-5">
       <div className="container">
         <h1>
           Our <span>Client</span>
         </h1>
         <img
           src="http://royalrubysalonandacademy.com/image.png"
           className="w-100"
           alt=""
         />
       </div>
     </div>
   </>
  )
}

export default OurClient