import React from 'react'

const NewsEvent = () => {
    document.title = "Metallica | News & Events"
  return (
    <>
    <div className="banner">
        <img src="https://dummyimage.com/1920x500" className='w-100' alt="" />
    </div>
    <div className="news-and-event py-5">
        <div className="container">
            <h1>News <span>&</span> Event</h1>
            <img src="http://royalrubysalonandacademy.com/image.png" className='w-100' alt="" />
        </div>
    </div>
    </>
  )
}

export default NewsEvent