import React, { useState, useEffect } from "react";
import Products from "./Products";
import banner from "../../../assets/images/metallica banner.png";
import plinth from "../../../assets/images/plinth.jpeg"
import pedstal from "../../../assets/images/pedstal.webp"
import c from "../../../assets/images/c.webp"

import axios from "axios";

const LaborataryFurniture = () => {
  document.title = "Metallica | Laboratary Furniture";
  const products = [
    {
      id: 2,
      name: "Plinth Frame Design",
      image: plinth,
      category: 2,
      sub_category: 9,
    },
    {
      id: 3,
      name: "Pedestal Frame Design",
      image: pedstal,
      category: 2,
      sub_category: 9,
    },
    {
      id: 4,
      name: "“C” Frame Design",
      image: c,
      category: 2,
      sub_category: 9,
    },
  ];

  return (
    <>
      <div className="banner">
        <img src={banner} className="w-100" alt="" />
      </div>
      <div className="product py-5 bg-white">
        <div className="container">
          <h1>
            <span>Laboratary</span> Furniture
          </h1>
          <Products products={products} />
        </div>
      </div>
    </>
  );
};

export default LaborataryFurniture;
