// App.js or index.js (main file)
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Home from "./components/pages/Home";
import CorporateOverview from "./components/pages/CorporateOverview";
import CleanRoomFurniture from "./components/pages/Product/CleanRoomFurniture";
import LaborataryFurniture from "./components/pages/Product/LaborataryFurniture";
import PassBox from "./components/pages/Product/PassBox";
import CleanRoomAccessories from "./components/pages/Product/CleanRoomAccessories";
import NewsEvent from "./components/pages/NewsEvent";
import Gallery from "./components/pages/Gallery";
import Download from "./components/pages/Download";
import OurClient from "./components/pages/OurClient";
import Contact from "./components/pages/Contact";
import AirFlowUnit from "./components/pages/Product/AirFlowUnit";
import AirShower from "./components/pages/Product/AirShower";
import Hood from "./components/pages/Product/Hood";
import Kiosk from "./components/pages/Product/Kiosk";
import MetailReatailShowroom from "./components/pages/Product/MetailReatailShowroom";

const App = () => {
  return (
    <BrowserRouter>
    <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route  path="/corporate-overview" element={<CorporateOverview />}></Route>
        <Route  path="/clean-room-furniture" element={<CleanRoomFurniture />}></Route>
        <Route  path="/laboratary-furniture" element={<LaborataryFurniture />}></Route>
        <Route  path="/pass-boxes" element={<PassBox />}></Route>
        <Route  path="/clean-room-accessories" element={<CleanRoomAccessories />}></Route>
        <Route  path="/news-and-event" element={<NewsEvent />}></Route>
        <Route  path="/gallery" element={<Gallery />}></Route>
        <Route  path="/download" element={<Download />}></Route>
        <Route  path="/our-client" element={<OurClient />}></Route>
        <Route  path="/contact-us" element={<Contact />}></Route>
        <Route  path="/air-flow-unit" element={<AirFlowUnit />}></Route>
        <Route  path="/air-shower" element={<AirShower />}></Route>
        <Route  path="/hood" element={<Hood />}></Route>
        <Route  path="/kiosk" element={<Kiosk />}></Route>
        <Route  path="/metail-retail-showroom" element={<MetailReatailShowroom />}></Route>
      </Routes>
    <Footer />
    </BrowserRouter>
  );
};

export default App;
