


import React, { useState } from "react";
import Products from "./Products";
import banner from "../../../assets/images/metallica banner.png";
import axios from "axios";

const CleanRoomFurniture = () => {
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const products = [
    {
      id: 1,
      name: "SS Canteen Table with Bench",
      image:
        "https://metallicacreation.com/media/product/ss-canteen-table-with-bench.png",
      category: 1,
      sub_category: 1,
    },
    {
      id: 3,
      name: "SS Dining table with stools 6 Seated",
      image:
        "https://metallicacreation.com/media/product/6-seater-canteen-dining-table.jpeg",
      category: 1,
      sub_category: 1,
    },
    {
      id: 4,
      name: "SS Folding Working Table",
      image:
        "https://metallicacreation.com/media/product/stainless-steel-folding-marriage-hall-dining-tables.png",
      category: 1,
      sub_category: 1,
    },
    {
      id: 5,
      name: "SS Inspection Table",
      image:
        "https://metallicacreation.com/media/product/ss-inspection-table.jpeg",
      category: 1,
      sub_category: 1,
    },
    {
      id: 6,
      name: "SS Office Table",
      image: "https://metallicacreation.com/media/product/ss-office-table.jpg",
      category: 1,
      sub_category: 1,
    },
    {
      id: 7,
      name: "SS Table with Drawers",
      image: "https://metallicacreation.com/media/product/ss-table-w-f.jpg",
      category: 1,
      sub_category: 1,
    },
    {
      id: 8,
      name: "SS Table with Skirting",
      image:
        "https://metallicacreation.com/media/product/stainless-table-500x500.webp",
      category: 1,
      sub_category: 1,
    },
    {
      id: 9,
      name: "SS Working table",
      image: "https://metallicacreation.com/media/product/SS-Work-Table.jpg",
      category: 1,
      sub_category: 1,
    },
    {
      id: 10,
      name: "SS Canteen table with MS Chairs",
      image:
        "https://metallicacreation.com/media/product/stainless-steel-canteen-table-500x500.webp",
      category: 1,
      sub_category: 1,
    },
    {
      id: 11,
      name: "Wooden Top Canteen Table with MS Chairs",
      image:
        "https://metallicacreation.com/media/product/product-jpeg-500x500.webp",
      category: 1,
      sub_category: 1,
    },
    {
      id: 12,
      name: "MS Mobile Stoage Locker(50 Storage)",
      image:
        "https://metallicacreation.com/media/product/ms-mobile-locker-500x500.webp",
      category: 1,
      sub_category: 2,
    },
    {
      id: 13,
      name: "MS-Staff-Locker-with-Storage-Cabinet",
      image:
        "https://metallicacreation.com/media/product/office-staff-locker-500x500.webp",
      category: 1,
      sub_category: 2,
    },
    {
      id: 14,
      name: "MS-Staff-Lockers-with-Partition",
      image:
        "https://metallicacreation.com/media/product/tuff-lockers-grouped_1.jpg",
      category: 1,
      sub_category: 2,
    },
    {
      id: 15,
      name: "MS Staff Lockes (4 Storage)",
      image: "https://metallicacreation.com/media/product/download.jpeg",
      category: 1,
      sub_category: 2,
    },
    {
      id: 16,
      name: "MS Staff Lockes (12 Storage)",
      image:
        "https://metallicacreation.com/media/product/12-storage-staff-locker-500x500.webp",
      category: 1,
      sub_category: 2,
    },
    {
      id: 17,
      name: "SS Apron Lockers",
      image:
        "https://metallicacreation.com/media/product/stainless-steel-apron-locker-cabinet-500x500.webp",
      category: 1,
      sub_category: 2,
    },
    {
      id: 18,
      name: "MS Staff Lockes (18 Storage)",
      image:
        "https://metallicacreation.com/media/product/front-view-18-compartment.jpeg",
      category: 1,
      sub_category: 2,
    },
    {
      id: 19,
      name: "SS Staff Lockes (20 Storage)",
      image:
        "https://metallicacreation.com/media/product/20-compartment-ss-locker.jpg",
      category: 1,
      sub_category: 2,
    },
    {
      id: 20,
      name: "SS Shoes Storage Locker",
      image:
        "https://metallicacreation.com/media/product/60-compartment-ss-shoe-locker-500x500.webp",
      category: 1,
      sub_category: 2,
    },
    {
      id: 21,
      name: "SS Staff Lockers (35-Storage)",
      image:
        "https://metallicacreation.com/media/product/gym-mild-steel-storage-locker-500x500.webp",
      category: 1,
      sub_category: 2,
    },
    {
      id: 22,
      name: "SS Cross Over Bench",
      image:
        "https://metallicacreation.com/media/product/ss-cross-over-bench-500x500.png",
      category: 1,
      sub_category: 3,
    },
    {
      id: 23,
      name: "SS Cross Over Bench C Type",
      image:
        "https://metallicacreation.com/media/product/ss-crossover-bench-500x500.webp",
      category: 1,
      sub_category: 3,
    },
    {
      id: 24,
      name: "SS Cross Over Bench C Type With Box",
      image:
        "https://metallicacreation.com/media/product/ss-cross-over-bench-500x500.webp",
      category: 1,
      sub_category: 3,
    },
    {
      id: 25,
      name: "SS Cross Over Bench With Box",
      image:
        "https://metallicacreation.com/media/product/ss-crossover-bench-500x500_1.webp",
      category: 1,
      sub_category: 3,
    },
    {
      id: 26,
      name: "SS Cross Over Bench With Flap",
      image:
        "https://metallicacreation.com/media/product/ss-cross-over-bench-160810904621032070.jpg",
      category: 1,
      sub_category: 3,
    },
    {
      id: 27,
      name: "SS Cross Over Bench With Slide Doors",
      image:
        "https://metallicacreation.com/media/product/sanitt-crossover-benches-with-sliding-door-500x500.webp",
      category: 1,
      sub_category: 3,
    },
    {
      id: 28,
      name: "MS File Storage Cabinet",
      image: "https://metallicacreation.com/media/product/51yKNR-AYfL.jpg",
      category: 1,
      sub_category: 4,
    },
    {
      id: 29,
      name: "SS Apron Cabinet",
      image:
        "https://metallicacreation.com/media/product/Mild-Steel-Apron-Cabinet.jpg",
      category: 1,
      sub_category: 4,
    },
    {
      id: 30,
      name: "SS Garment Hanging Cabinet",
      image:
        "https://metallicacreation.com/media/product/ss-garment-hanging-cabinet-500x500.webp",
      category: 1,
      sub_category: 4,
    },
    {
      id: 31,
      name: "SS Garment Storage Cabinet",
      image:
        "https://metallicacreation.com/media/product/garment-storage-cabinet-500x500.webp",
      category: 1,
      sub_category: 4,
    },
    {
      id: 32,
      name: "SS Sieve Cabinet with Screen Storage",
      image:
        "https://metallicacreation.com/media/product/sieve-storage-cabinet.jpg",
      category: 1,
      sub_category: 4,
    },
    {
      id: 33,
      name: "SS Die Punch Cabinet 12 Drawers",
      image:
        "https://metallicacreation.com/media/product/10-drawer-ss-punch-die-cabinet.jpg",
      category: 1,
      sub_category: 4,
    },
    {
      id: 34,
      name: "SS Open Type Shoes Rack",
      image:
        "https://metallicacreation.com/media/product/stainless-steel-shoe-rack-863.jpg",
      category: 1,
      sub_category: 5,
    },
    {
      id: 35,
      name: "SS Shoes Rack Cabinet",
      image: "https://metallicacreation.com/media/product/shoe-cabinet.jpg",
      category: 1,
      sub_category: 4,
    },
    {
      id: 36,
      name: "SS Strorage Rack",
      image:
        "https://metallicacreation.com/media/product/ss-storage-racks-500x500.webp",
      category: 1,
      sub_category: 5,
    },
    {
      id: 37,
      name: "MS Storage Rack",
      image:
        "https://metallicacreation.com/media/product/slotted-angle-iron-racks-500x500.webp",
      category: 1,
      sub_category: 5,
    },
    {
      id: 38,
      name: "SS Fix Chair with Tilting Back Rest",
      image: "https://metallicacreation.com/media/product/ss-fix-chair.jpg",
      category: 1,
      sub_category: 6,
    },
    {
      id: 39,
      name: "SS Lab Revolving Chair",
      image:
        "https://metallicacreation.com/media/product/ss-revolving-lab-chairs.png",
      category: 1,
      sub_category: 6,
    },
    {
      id: 40,
      name: "SS Revolving Chair",
      image:
        "https://metallicacreation.com/media/product/ss-revolving-chair-500x500.webp",
      category: 1,
      sub_category: 6,
    },
  ];

  // Filter products based on the selected sub-category
  const filteredProducts = selectedSubCategory
    ? products.filter((product) => product.sub_category === selectedSubCategory)
    : products;

  return (
    <>
      {/* ... (your banner code remains the same) */}
      <div className="product py-5 bg-white">
        <div className="container">
          <h1>
            <span>Clean Room</span> Furniture
          </h1>
          {/* ... (your introductory text remains the same) */}
          
          {/* Add a dropdown for sub-categories */}
          <div className="d-flex justify-content-between">
            <label htmlFor="subCategory">Filter by Sub-Category:</label>
            <div className="col-md-3">
            <select
              id="subCategory"
              className="form-select"
              onChange={(e) => setSelectedSubCategory(Number(e.target.value))}
              value={selectedSubCategory || ""}
            >
              <option value="" selected>All</option>
              <option value={1}>Clean Room Tables</option>
              <option value={2}>Clean Room Lockers</option>
              <option value={3}>Clean Room Cross Over Benches</option>
              <option value={4}>Clean Room Cabinets</option>
              <option value={5}>Clean Room Storage Racks</option>
              <option value={6}>Clean Room Chairs</option>
              <option value={7}>Clean Room Stools</option>
              <option value={8}>Clean Room Trolleys</option>
              {/* Add more options based on your sub-categories */}
            </select>
            </div>
          </div>

          {/* Pass the filtered products to the Products component */}
          <Products products={filteredProducts} />
        </div>
      </div>
    </>
  );
};

export default CleanRoomFurniture;
