import React from "react";

const Products = ({ products }) => {
  return (
    <>
      <div className="row py-5 gap-4 justify-content-center">
        {products.map((product) => (
          <div className="card text-center shadow-sm" key={product.id} style={{width: '25rem'}}>
            <img src={product.image} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">{product.name}</h5>
              <hr />
              <a href="https://wa.me/919999080543" target="_blank" className="text-center btn btn-sm btn-primary">
                Inquiry
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Products;
