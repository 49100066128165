import React from 'react'
import Products from "./Products";
import banner from "../../../assets/images/metallica banner.png";
import air1 from "../../../assets/images/2shower.jpg"
import air2 from "../../../assets/images/air-shower.png"

const AirShower = () => {
    document.title = "Metallica | Air Flow Unit";
    const products = [
      {
        id: 1,
        name: "Two Door Air Shower",
        image: air1
      },
      {
        id: 2,
        name: "Single Door Air Shower",
        image: air2
      },
    ] 
  return (
    <>
    <div className="banner">
      <img src={banner} className="w-100" alt="" />
    </div>
    <div className="product py-5 bg-white">
      <div className="container">
        <h1>
          <span>Air Flow</span> Unit
        </h1>
        <p>
         
        </p>
        <Products products={products} />
      </div>
    </div>
  </>
  )
}

export default AirShower