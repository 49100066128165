import React from 'react'
import Products from "./Products";
import banner from "../../../assets/images/metallica banner.png";
import h1 from "../../../assets/images/hanging-laminar.jpg"
import h2 from "../../../assets/images/h-laminar.jpeg"
import h3 from "../../../assets/images/v-laminar.jpg"

const AirFlowUnit = () => {
    document.title = "Metallica | Air Flow Unit";
    const products = [
      {
        id: 1,
        name: "Hanging Laminar",
        image: h1
      },
      {
        id: 2,
        name: "Horizontal Laminar",
        image: h2
      },
      {
        id: 3,
        name: "Vertical Laminar",
        image: h3
      },
    ] 
  
  return (
    <>
    <div className="banner">
      <img src={banner} className="w-100" alt="" />
    </div>
    <div className="product py-5 bg-white">
      <div className="container">
        <h1>
          <span>Air Flow</span> Unit
        </h1>
        <p>
         
        </p>
        <Products products={products} />
      </div>
    </div>
  </>
  )
}

export default AirFlowUnit