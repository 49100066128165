import React from "react";
import banner from "../../assets/images/metallica banner.png"
import company1 from "../../assets/images/company-1.jpeg"
import company2 from "../../assets/images/company-2.jpeg"
import g1 from "../../assets/images/g1.jpeg"
import g2 from "../../assets/images/3.png"
import g3 from "../../assets/images/galley-8.jpeg"
import g9 from "../../assets/images/g-9.jpeg"
import g10 from "../../assets/images/g-10.jpeg"

const Gallery = () => {
    document.title = "Metallica | Gallery"
  return (
    <>
      <div className="banner">
        <img src={banner} className="w-100" alt="" />
      </div>
      <div className="gallery py-5">
        <div className="container">
          <h1 className="text-center">
            Some <span>Pictures</span>
          </h1>
         <div className="row py-5 gap-2 justify-content-center">
          <div className="col-md-5">
            <img src={company1} className="w-100 bg-white p-1 shadow-sm rounded" alt="" />
          </div>
          <div className="col-md-6">
            <img src={company2} className="w-100 bg-white p-1 shadow-sm rounded" alt="" />
          </div>
          <div className="col-md-6">
            <img src={g1} className="w-100 bg-white p-1 shadow-sm rounded" alt="" />
          </div>
          <div className="col-md-5">
            <img src={g2} className="w-100 bg-white p-1 shadow-sm rounded" alt="" />
          </div>
          <div className="col-md-11">
            <img src={g3} className="w-100 bg-white p-1 shadow-sm rounded" alt="" />
          </div>
          <div className="col-md-6">
            <img src={g9} className="w-100 bg-white p-1 shadow-sm rounded" alt="" />
          </div>
          <div className="col-md-5">
            <img src={g10} className="w-100 bg-white p-1 shadow-sm rounded" alt="" />
          </div>
         </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
