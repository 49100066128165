import React from "react";
import Products from "./Products";
import banner from "../../../assets/images/metallica banner.png";
import pass1 from "../../../assets/images/p1.png"
import pass2 from "../../../assets/images/p2.png"



const PassBox = () => {
  document.title = "Metallica | Pass Boxes";
  const products = [
    {
      id: 1,
      name: "Static Pass Box",
      image: pass1
    },
    {
      id: 2,
      name: "Dyanamic Pass Box",
      image: pass2
    },
  ] 

 
  return (
    <>
      <div className="banner">
        <img src={banner} className="w-100" alt="" />
      </div>
      <div className="product py-5 bg-white">
        <div className="container">
          <h1>
            <span>Pass</span> Boxes
          </h1>
          <p>
            We are engaged in offering our clients tables that made from SS 304
            / SS 316 with matt finish. The tables made as per customer
            application and quality norms of the market, thus, durability and
            longer working life is always ensured. The tables are extensively
            used in laboratories, biopharmaceuticals, hospitals, canteen & food
            industries. The tables will be cost-effective and user-friendly. The
            supportive service team will help the customers to solve their
            problems very frequently. The good quality of the material used in
            making the products will increase the life span of the products. The
            proper quality and standards of the products will be maintained by
            our company. The excellent and satisfying quality of the products
            will help in achieving the goals of the company. The cleanroom
            tables are self-adjustable and manageable.
          </p>
          <Products products={products} />
        </div>
      </div>
    </>
  );
};

export default PassBox;
