import React from 'react'
import Products from "./Products";
import img from "../../../assets/images/m-r-s.png"
import banner from "../../../assets/images/metallica banner.png";

const MetailReatailShowroom = () => {
    document.title = "Metallica | Metail Retail Showroom";
    const products = [
      {
        id: 1,
        name: "Metail Retail Showroom",
        image: img
        
      },

    ] 
  return (
    <>
    <div className="banner">
     <img src={banner} className="w-100" alt="" />
   </div>
   <div className="product py-5 bg-white">
     <div className="container">
       <h1 className='text-center'>
         <span>Metail Retail Showroom</span>
       </h1>
       <p>
        
       </p>
       <Products products={products} />
     </div>
   </div>
   </>
  )
}

export default MetailReatailShowroom