import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandWhatsapp,
  IconBrandYoutube,
  IconChevronDown,
  IconMailFilled,
  IconMenu,
  IconPhoneFilled,
  IconX,
} from "@tabler/icons-react";
import {Link}  from 'react-router-dom';
import React from "react";
import { useState } from 'react';
import Logo from '../../assets/images/logo.png'


const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="topbar p-2">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="left d-flex gap-4">
              <a
                href="mailto:info@metallicacreation.com"
                target="_blank"
                rel="noreferrer"
                className="d-flex gap-2"
              >
                <IconMailFilled width={16} />{" "}
                <span>info@metallicacreation.com</span>
              </a>
              <a
                href="tel:+91-7082000473"
                target="_blank"
                rel="noreferrer"
                className="d-flex gap-2"
              >
                <IconPhoneFilled width={16} /> <span>+91-7082000473</span>
              </a>
            </div>
            <div className="right d-flex gap-2">
              <a href="#">
                <IconBrandInstagram width={16} />
              </a>
              <a href="#">
                <IconBrandFacebook width={16} />
              </a>
              <a href="#">
                <IconBrandYoutube width={16} />
              </a>
              <a href="#">
                <IconBrandLinkedin width={16} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={Logo} width={125} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <IconX /> : <IconMenu />}
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav gap-3">
            <li className="nav-item">
                <Link className="nav-link" title="Home" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" title="Corporate Overview" to="/corporate-overview">
                  Corporate Overview
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Product <IconChevronDown width={16} />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" title="Clean Room Furniture" to="/clean-room-furniture">
                      Clean Room Furniture
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" title="Laboratary Furniture" to="/laboratary-furniture">
                      Laboratary Furniture
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" title="Pass Box" to="pass-boxes">
                      Pass Box
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" title="Air Flow Unit" to="air-flow-unit">
                      Air Flow Unit
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" title="Air Shower" to="air-shower">
                      Air Shower
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" title="Hood" to="hood">
                      Hood
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" title="Booth" to="booth">
                      Booth
                    </Link>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" title="Kiosk" to="kiosk">
                      Kiosk
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" title="Metail Retail Showroom" to="metail-retail-showroom">
                    Metail Retail Showroom
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" title="News & Event" to="news-and-event">
                  News & Event
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" title="Gallery" to="gallery">
                  Gallery
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" title="Download" to="download">
                  Download
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" title="Our Client" to="our-client">
                  Our Client
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link primary-button" title="Contact Us" to="contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="side-icons">
        <li>
          <a
            href="mailto:info@metallicacreation.com"
            rel="noreferrer"
            target="_blank"
            className="d-flex gap-2"
          >
            <IconMailFilled width={16} />
          </a>
        </li>
        <li>
          {" "}
          <a
            rel="noreferrer"
            href="tel:+91-7082000473"
            target="_blank"
            className="d-flex gap-2"
          >
            <IconPhoneFilled width={16} />{" "}
          </a>
        </li>
        <li>
          <a href="https://wa.me/919999080543" target="_blank">
            <IconBrandWhatsapp width={16} />
          </a>
        </li>
      </div>
    </>
  );
};

export default Navbar;
